import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import Pricing from '../DigitalMarketingAgency/Pricing';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/web-team.json';
import ServicesWeb from '../Services/ServicesWeb';

const ServiceWebContent = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">

                        <div className="services-details-desc">
                            <span className="sub-title">Création de site web</span>
                            <h3>Un site adpaté à vos besoins</h3>

                            <ServicesWeb />

                            <p>Vous voulez un nouveau site Web, mais vous ne savez pas par où commencer ? Nous vous accompagnons à travers toutes les étapes de la création de votre nouveau super site web. Que vous soyez une grande entreprise ou un petit commerçant, Yummy Technologies s'adaptera à votre situation et vous fournira le site Internet dont vous avez besoin.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="services-details-image">
                                        <Lottie
                                            options={defaultOptions}
                                            height="100%"
                                            width="100%"
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Une solution complète</h3>
                                        <ul className="features-list">
                                            <li><span> Nom de domaine</span></li>
                                            <li><span> Développeur dédié</span></li>
                                            <li><span> Site optimisé</span></li>
                                            <li><span> Mises à jour d'avancement</span></li>
                                            <li><span> Révisions</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>



                            <p>Afin de vous démarquer de la masse des sites internet déjà en ligne, vous devez avoir un site internet qui saura capter l’attention de vos clients. Chez Yummy Technologies, nous développons des sites Web conçu pour ça. Ne vous contentez pas d'un site Web banal, demandez à nos professionnels de créer votre vitrine en ligne pour une conversion maximale !</p>

                            <h3>Un site à la pointe de la technologie</h3>
                            <ul className="technologies-features">
                                <li><span>HTML/CSS</span></li>
                                <li><span>JavaScript</span></li>
                                <li><span>TypeScript</span></li>
                                <li><span>React</span></li>
                                <li><span>Node.js</span></li>
                                <li><span>Angular</span></li>
                                <li><span>Firebase</span></li>
                                <li><span>MongoDB</span></li>
                                <li><span>BootStrap</span></li>
                                <li><span>Express</span></li>
                                <li><span>WooCommerce</span></li>
                                <li><span>WordPress</span></li>
                            </ul>

                            {/* <Pricing /> */}


                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceWebContent