import React from 'react'
import { Link } from 'gatsby'
import webCorporate from '../../assets/images/services/web-corporate.png';
import webShop from '../../assets/images/services/web-shop.png';
import webLanding from '../../assets/images/services/web-landing.png';



const ServicesOne = () => {
    return (
        <section className="solutions-area ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-3 col-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={webCorporate} width="40%" alt="Site pour entreprise" title="Site pour entreprise"/>
                            </div>

                            {/* <h4>
                                    Page d'accueil/promo
                            </h4> */}

                            <p><b>Site d'entreprise</b></p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-3 col-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={webLanding} width="40%" alt="Création de landing page" title="Création de landing page"/>
                            </div>

                            {/* <h4>
                                    Page d'accueil/promo
                            </h4> */}

                            <p><b>Page d'acceuil</b></p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-3 col-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={webShop} width="40%" alt="Création site e-commerce" title="Création site e-commerce" />
                            </div>

                            {/* <h4>
                                    Page d'accueil/promo
                            </h4> */}

                            <p><b>Site E-commerce</b></p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne